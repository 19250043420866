import React, { useEffect, useState } from "react";
import selectYourDiamondService from "../services/select-your-diamond.service";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  const search = useLocation().search;
  const paramDealerId = new URLSearchParams(search).get("DealerLink");
  const [visible, setVisible] = useState(false);
  const [whiteLableName, setWhiteLableName] = useState({});
  useEffect(() => {
    handleGetMenuList(paramDealerId);
    handleGetWhiteLabel();
  }, []);

  const handleGetMenuList = (Id) => {
    try {
      let inputData = {
        dealerId: Id,
      };

      selectYourDiamondService
        .GetMenuList(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;

          if (message == "Success") {
            setVisible(responseData.diamondSearchMenu.chkDSSPBy);
          } else {
            setVisible(false);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetWhiteLabel = () => {
    try {
      selectYourDiamondService
        .CompanyWhileLabel()
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message == "Success") {
            if (responseData) {
              setWhiteLableName(responseData[0]);
            } else {
              setWhiteLableName({});
            }
          } else {
            setWhiteLableName({});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="powered__by">
      {visible == true && (
        <a
          href={whiteLableName?.footerLogoLink || "#"}
          target="_blank"
          className="text-dark text-decoration-none"
        >
          {t("Powered By")} {whiteLableName?.name ?? "GemFind"}
        </a>
      )}
    </div>
  );
};

export default Footer;
