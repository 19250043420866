import { httpApps } from "../helper/http-common";

class ColorPanelServices {
  GetHoverEffect(inputData) {
    return httpApps.post("RingBuilderForML/GetHoverEffect", inputData);
  }
  AddUpdateHoverEffect(inputData) {
    return httpApps.post("RingBuilderForML/AddUpdateHoverEffect", inputData);
  }
}

export default new ColorPanelServices();
