import React, { Fragment } from "react";
import SelectYourDiamond from "./ApplicationPreview/SelectYourDiamond/SelectYourDiamond";
import Footer from "./footer";
import ColorPanel from "./ColorPanel";

export const Layout = (props) => {
  return (
    <Fragment>
      <div className="form__fields m-2 iframe__block p-0 border-0 bg-transparent">
        <div>
          <SelectYourDiamond />
        </div>
        {props.children}
        <Footer />
        <ColorPanel />
      </div>
    </Fragment>
  );
};

export default Layout;
