import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Layout from "./component/Layout";
import ColorPanel from "./component/ColorPanel";
import { useCookies } from "react-cookie";
import i18n from "./i18n";

const CompleteYourRing = lazy(() =>
  import("./component/ApplicationPreview/CompleteYourRing")
);
const MinedTab = lazy(() =>
  import("./component/ApplicationPreview/SelectYourDiamond/MinedTab")
);
const Lab = lazy(() =>
  import("./component/ApplicationPreview/SelectYourDiamond/LabGrown")
);
const FancyTab = lazy(() =>
  import("./component/ApplicationPreview/SelectYourDiamond/FancyColor")
);
const Compare = lazy(() =>
  import("./component/ApplicationPreview/SelectYourDiamond/Compare")
);
const Request = lazy(() =>
  import("./component/ApplicationPreview/SelectYourDiamond/Request")
);

const PrivateRoute = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

const App = () => {
  const fontStyle = useSelector(
    (state) => state.previewColorReducer.fontFamily
  );

  const [cookies] = useCookies(["lngCookie"]);

  const language = cookies.lngCookie;

  useEffect(() => {
    if (language) {
      //// check langauge name for conditions
      let tempLanguage =
        language === "English" || language === "en"
          ? "en"
          : language === "Spanish" || language === "es"
          ? "es"
          : language === "Arabic" || language === "ar"
          ? "ar"
          : "en";
      i18n.changeLanguage(tempLanguage);
    }
  }, [language, i18n]);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty(
      "--dynamic-font-family",
      fontStyle || "LexendDeca-Regular"
    );
  }, [fontStyle]);

  return (
    <div style={{ fontFamily: fontStyle ? fontStyle : "LexendDeca-Regular" }}>
      <Suspense fallback={""}>
        <BrowserRouter>
          <Routes>
            <Route path="/viewDiamondDetails" element={<CompleteYourRing />} />
            <Route exact path="/" element={<PrivateRoute />}>
              <Route path="/Mined" element={<MinedTab />} />
              <Route path="/Lab" element={<Lab />} />
              <Route path="/Fancy" element={<FancyTab />} />
              <Route path="/Request" element={<Request />} />
              <Route path="/Compare" element={<Compare />} />
            </Route>
          </Routes>
          <ColorPanel />
        </BrowserRouter>
      </Suspense>
    </div>
  );
};

export default App;
