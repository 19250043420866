import { combineReducers } from "redux";
import pageNavigationReducer from "./PageNavigations/PageNavigations";
import previewColorReducer from "./PreviewColor/PreviewColor";
import loginDetailsReducer from "./LoginDetails/LoginDetails";
import selectYourDiamondReducer from "./SelectYourDiamond/selectyourdiamond";
import { RESET_APP } from "../actions/LoginDetails/types";

const appReducer = combineReducers({
  pageNavigationReducer,
  previewColorReducer,
  loginDetailsReducer,
  selectYourDiamondReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    // for all keys defined in your persistConfig(s)
    window.localStorage.removeItem("persist:reducer");
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
